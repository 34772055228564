import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import Banner from '../components/Banner';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';

import * as styles from './shenclinic.module.css';
import Button from '../components/Button/Button';
const WomensHealthPage = (props) => {
  let infertilityRef = useRef();
  let acupunctureRef = useRef();
  let herbalMedRef = useRef();
  let bodyTempRef = useRef();
  let drShenRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom title={"Women's Health"}>
      <div className={styles.root}>
        {/* Hero Container */}
        <Banner
          maxWidth={'900px'}
          bgImage={'/background/pink_flower_open.jpg'}
          height={'300px'}
          name={`Women's Health \n `}
          color={'var(--standard-white)'}
        />

        <div className={styles.navContainer}>
          <ThemeLink
            onClick={() => handleScroll(infertilityRef)}
            to={'#infertility'}
          >
            Women's Health and Infertility
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(acupunctureRef)}
            to={'#acupuncture'}
          >
            Acupuncture
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(herbalMedRef)}
            to={'#herbalmedicine'}
          >
            Herbal Medicine
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(bodyTempRef)}
            to={'#basalbodytemp'}
          >
            Basal Body Temperature
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(drShenRef)} to={'#drshen'}>
            Dr. Shen's Credentials
          </ThemeLink>
        </div>

        {/* <Container size={'large'} spacing={'min'}>
          <div className={styles.detailContainer} ref={historyRef}>
            <p>
              Founded in 1860, Sydney is an innovative British brand with a
              contemporary edge. We make timeless everyday luxury clothing.
            </p>
            <br />
            <br />
            <p>
              We created some of the world's first T-shirts and spent decades
              perfecting the feel of the cotton. Today we are the only brand
              that makes T-shirts in its own factory in the UK. And we do this
              in the same factory we have occupied since 1937.
            </p>
          </div>
        </Container> */}

        {/* <div className={styles.imageContainer}>
          <img alt={'shirt brand'} src={'/about1.png'}></img>
        </div> */}
        <br></br>
        <br />
        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>Women's Health and Infertility</h3>
            <div ref={infertilityRef}>
              <p>
                Infertility is a growing problem due to the choice of many to
                have children later in life. The impact of environmental and
                lifestyle factors influence both female and male reproductive
                systems. Many perceive in vitro fertilization (IVF) to be the
                last remaining hope as far as achieving a successful pregnancy
                goes. However, live birth rates from IVF range from 31.9% for
                35-37 year old women to 12.5% for 41-42 year olds. Due to a
                signiﬁcant amount of emotional and ﬁnancial stress associated
                with IVF, many women now seek other treatment options in order
                to reduce stress levels, improve egg quality, and enhance their
                IVF success rates.
              </p>
              <p></p>
            </div>
          </div>
          <div className={styles.content}>
            <h3>Acupuncture </h3>
            <div ref={acupunctureRef}>
              <p>
                Acupuncture is a common choice practiced among many. Randomized
                controlled trials (RCTs) investigating the impact of acupuncture
                on IVF have been undertaken. In 2002, German doctor Paulus and
                his colleagues published the first prospective study on the
                positive impact of acupuncture before and after embryo transfer.
                Pregnancy rates were compared within 160 IVF patients. The
                patients that received acupuncture treatments had a 42.5%
                clinical pregnancy rate compared to a 26.3% rate within the
                control group.This data is accessible to patients and biomedical
                practitioners and can be used as an endorsement for the use of
                acupuncture to improve IVF success rates.
              </p>
              <p>
                Acupuncture is an ancient, therapeutic art, which has received
                renewed attention in light of recent scientific research. It is
                currently being integrated within modern medical practices for
                the treatment of a wide range of diseases, including
                infertility. In the past three decades, the practice of
                acupuncture has grown within the United States. According to the
                NIH(National Institutes of Health) Consensus Statement on
                Acupuncture, there have been many studies on acupuncture’s level
                of usefulness. It is proposed that acupuncture produces its
                effects through regulating the nervous and endocrine systems.
                This releases chemicals within the reproductive axis. There are
                twenty major pathways that Qi (energy flow) is believed to
                travel through and these pathways, or meridians, are accessible
                through 400 different acupuncture points. Acupuncture has shown
                effective results in controlled, scientific studies involving
                ovulation induction, endometriosis, PCOS, and male infertility.
              </p>
              <p>
                Acupuncture is a common choice practiced among many. Randomized
                controlled trials (RCTs) investigating the impact of acupuncture
                on IVF have been undertaken. In 2002, German doctor Paulus and
                his colleagues published the first prospective study on the
                positive impact of acupuncture before and after embryo transfer.
                Pregnancy rates were compared within 160 IVF patients. The
                patients that received acupuncture treatments had a 42.5%
                clinical pregnancy rate compared to a 26.3% rate within the
                control group.This data is accessible to patients and biomedical
                practitioners and can be used as an endorsement for the use of
                acupuncture to improve IVF success rates.
              </p>
              
            </div>
            <h3>Herbal Medicine</h3>
            <div ref={herbalMedRef}>
              <p>
                In China, herbal medicine has over two thousand years of history
                and is used extensively for the treatment of a wide range of
                gynecological conditions including both female and male
                infertility by balancing hormonal conditions. In this way,
                herbal medicine treats not only the symptoms, but the root cause
                of infertility. This significantly increases success rates.
              </p>
              <p>
                Our clinic has more than 600 different herbs. An individual’s
                personal herbal formula contains at least 12 ingredients. Over
                the centuries, herbal prescriptions have continued to evolve
                thanks to prevailing theories, clinical evidence, and scientific
                research. In Dr. Shen’s animal studies, herbs have shown
                effective results related to inducing ovulation in mice, as
                shown in photos below.
              </p>
              <p>
                A recent systematic review investigating the effect of Chinese
                herbal medicine on pregnancy rates compared to that of assisted
                reproductive technology intervention showed that Chinese herbal
                medicine improved pregnancy rates two-fold within a four month
                period versus only fertility drug therapy, or IVF. A recent
                review also demonstrated the effectiveness of Chinese herbal
                medicine for endometriosis.
              </p>
              <div className={styles.horizontal}>
                <img
                  alt={'Control Group Mouse Ovary'}
                  src={'/infertility/control_group_mouse_ovary.jpg'}
                ></img>
                <img
                  alt={'Herbal Group Mouse Ovary'}
                  src={'/infertility/herbal_group_mouse.jpg'}
                ></img>
              </div>
              <p>
                The mechanisms through which herbal medicineand acupuncture
                influence female fertility are believed to involve the central
                stimulation of beta-endorphin secretions. This in turn impacts
                the GnRH pulse generator and thereby gonadotrophin and steroid
                secretions. Then a general sympatho-inhibitory effect occurs
                through increased blood flow to the uterus and ovaries,
                resulting in uterine conditions favoring implantation. Several
                potential mechanisms for its effects on fertility have been
                postulated: (1.)It improves ovary functions to help produce
                better quality eggs and a larger number of follicles. (2.)
                Increases blood flow to the uterus and therefore increases the
                uterine lining thickness. (3.) Improves male semen to create
                better quality and quantity of embryos.(4.) Lessens Western
                IVFdrug side effects. (5.) Decreases the chance of miscarriage.
                (6.) Regulates and balances hormones. (7.) Relaxes the patient
                and decreases her stress.
              </p>
              
              <p>
                Our clinical experience indicates that Chinese herbs increase
                endometrial thickness, improve fertility and embryo quality, as
                well as promote embryonic implantation. This enhances the
                success rate of an IVF/ICSI/IUI cycle, even when taking the
                pharmaceutical drug Clomid. Chinese herbs can also help prevent
                uterine contractions, strengthen a patient’s immune system, and
                decrease the chance of miscarriage. In conclusion, Chinese herbs
                help to improve the outcome and safety of assisted reproductive
                technologies.
              </p>
              <p>
                Early treatment enhances and improves the quality and quantity
                of oocytes. TCM has attached great importance to prevention.
                Therefore, three months before IVF treatment, we encourage the
                use of Chinese herbal medicine and/or acupuncture treatment to
                improve egg quality. This is especially important for patients
                with a diminished ovarian reserve.
              </p>
            </div>

            <h3>Basal Body Temperature</h3>
            <div ref={bodyTempRef}>
              <p>
                Your basal body temperature (BBT) is your lowest body
                temperature in a 24-hour period. You will need to use a basal
                thermometer, which measures small changes in body temperature.
                Before ovulation, your BBT will probably range from 97.2 to
                about 97.7 degrees Fahrenheit. But the day after you ovulate,
                hormonal changes cause a rise of 0.4 to 1.0 degree in your BBT.
                You may notice your temperature spiking on other days here and
                there, but unless it stays up, you probably haven’t ovulated
                yet. If you become pregnant, your temperature will stay elevated
                throughout your pregnancy.
              </p>

              <h4>Benefits of Checking Basal Body Temperature</h4>

              <ol>
                <li>Monitoring your ovulation.</li>
                <li>No side effects since this is a natural method.</li>
                <li>Estimates if you have any luteal phase defects.</li>
                <li>
                  Women grow more knowledgeable about their body changes and
                  canpredict their next menstruation.
                </li>
              </ol>

              <h4>How can you check your BBT?</h4>

              <ol>
                <li>
                  Check your temperature immediately after waking up and before
                  getting out of bed. Make sure you’ve had at least six hours of
                  sleep.
                </li>
                <li>
                  Always use the same thermometer when measuring your BBT.
                </li>
                <li>Check your temperature at the same time each day.</li>
                <li>
                  Take your temperature by mouth, under the tongue, and for the
                  same time period.
                </li>
              </ol>

              {/* <h4>BBT chart showing luteal phase defects</h4>

              <ol>
                <li>
                  Follicular Phase (Low Temperature) shifts up slowly to the
                  Luteal Phase (Higher Temperatures) and takes over 3 days.
                </li>
                <li>
                  In Luteal Phase (Higher Temperatures) for less than 9 days.
                </li>
                <li>
                  Increase in temperature of less than 0.5 degrees Fahrenheit or
                  0.3 degrees Celsius.
                </li>
              </ol> */}
            </div>
            <h3>Dr. Shen's Credentials</h3>
            <div ref={drShenRef}>
              <p>
                Dr. Shen is one of the most outstanding professionals in the
                field of both Eastern and Western Medicine with over 30 years of
                clinic experience. He received a Master’s Degree in Obstetrics
                and Gynecology (Ob/Gyn) at Nanjing University of TCM, China. Dr.
                Shen was a faculty member at Nanjing University’s Department of
                Ob/Gyn, where he joined the gynecology infertility team
                partaking in clinical work while educating and researching. He
                earned a Ph.D. Degree in Ob/Gyn, at Mie University School of
                Medicine, Japan. Dr. Shen also served as a fellow doctor at the
                Department of Gynecology, Tokyo University and completed his
                post-doctoral fellowship at the Vanderbilt University School of
                Medicine, Tennessee. He was a professor and clinical director at
                South Baylo University, School of Herbal Medicine and
                Acupuncture.
              </p>
              <p>
                Dr. Shen is a well-trained reproductive field specialist as well
                as a renowned infertility and gynecology pioneer. He has
                contributed to a variety of scientific papers as well as
                research data on the treatment of infertility. His approach
                focuses on the use of herbal medicine and acupuncture.
              </p>
            </div>
          </div>
        </Container>

        {/* <div className={styles.imageContainer}>
          <img alt={'shirt backwards'} src={'/about3.png'}></img>
        </div> */}
      </div>
    </Layout>
  );
};

export default WomensHealthPage;
